/**
 * Text mapping for RUO and IVD equivalent texts.
 * Only put i18n text here for attributes like tooltip, etc. which needs toggle based on RUO/IVD flag.
 * For regular i18n, please follow Angular's i18n practice: https://angular.io/guide/i18n-overview
 */
const i18nTextMap = {
  'case-id': $localize`:Case ID text and tooltip for IVD@@case-id:Case ID`,
  'research-case-id': $localize`:Research case id text and tooltip for RUO@@research-case-id:Research case ID`,
  'edit-report-summary': $localize`:ViewCasePage.Icon.MatTooltip@@edit-report-summary:Edit report summary`,
  'edit-analysis-report-summary': $localize`:ViewCasePage.Icon.MatTooltip@@edit-analysis-report-summary:Edit analysis report summary`,
  'report-is-locked': $localize`:ViewCasePage.Icon.MatTooltip@@report-is-locked:Report is locked`,
  'analysis-report-is-locked': $localize`:ViewCasePage.Icon.MatTooltip@@analysis-report-is-locked:Analysis report is locked`,
  'clinical-trials-are-loading': $localize`:ViewCasePage.Approval.Disable.Message@@clinical-trials-are-loading:Clinical trials are loading.`,
  'potentially-relevant-clinical-trials-are-loading': $localize`:ViewCasePage.Approval.Disable.Message@@potentially-relevant-clinical-trials-are-loading:Potentially relevant clinical trials are loading.`,
  'clinical-trials-did-not-load': $localize`:ViewCasePage.Approval.Error.Message@@clinical-trials-did-not-load:Clinical trials did not load.`,
  'potentially-relevant-clinical-trials-did-not-load': $localize`:ViewCasePage.Approval.Error.Message@@potentially-relevant-clinical-trials-did-not-load:Potentially relevant clinical trials did not load.`,
  'variant-combination-clinical-brief': $localize`:ClinicalAnnotationCard.CuratedContent.Title@@variant-combination-clinical-brief:Variant combination clinical brief`,
  'variant-combination-brief': $localize`:ClinicalAnnotationCard.CuratedContent.Title@@variant-combination-brief:Variant combination brief`,
  'variant-combination-clinical-summary': $localize`:ClinicalAnnotationCard.CuratedContent.Title@@variant-combination-clinical-summary:Variant combination clinical summary`,
  'variant-combination-summary': $localize`:ClinicalAnnotationCard.CuratedContent.Title@@variant-combination-summary:Variant combination summary`,
  'appears-on-the-first-page-of-report': $localize`:VariantPage.ReportSummaryInput.Hint@@appears-on-the-first-page-of-report:appears on the first page of report`,
  'appears-on-the-first-page-of-analysis-report': $localize`:VariantPage.ReportSummaryInput.Hint@@appears-on-the-first-page-of-analysis-report:appears on the first page of analysis report`,
  'patient-name': $localize`:Patient name IVD@@case-detail-side-bar-name:Patient name`,
  'patient-subject-name': $localize`:Subject name RUO@@case-detail-side-bar-subject-name:Subject name`,
  'patient-dob': $localize`:patient DOB IVD@@case-detail-side-bar-dob:Patient DOB`,
  'patient-subject-dob': $localize`:Subject DOB RUO@@case-detail-side-bar-subject-dob:Subject DOB`,
  'patient-date-of-birth': $localize`:patient date of birth IVD@@patient-date-of-birth:Patient date of birth`,
  'subject-date-of-birth': $localize`:subject date of birth RUO@@subject-date-of-birth:Subject date of birth`,
  'patient-ethnicity': $localize`:Patient ethnicity IVD@@case-detail-side-bar-ethnicity:Patient ethnicity`,
  'patient-subject-ethnicity': $localize`:Subject ethnicity RUO@@patient-subject-ethnicity:Subject ethnicity`,
  'edit-case': $localize`:Edit case IVD@@case-detail-side-bar-edit-case:Edit case`,
  'edit-research-case': $localize`:Edit research case RUO@@case-detail-side-bar-edit-research-case:Edit research case`,
  'patient-mrn': $localize`:Patient MRN IVD@@patient-mrn:Patient MRN`,
  'subject-mrn': $localize`:Subject MRN RUO@@subject-mrn:Subject MRN`,
  'patient-medical-record-number': $localize`:Placeholder indicating this field is realted to Medical Record Number@@new-case-page-medical-record-number:Patient medical record number`,
  'subject-medical-record-number': $localize`:Placeholder indicating this field is realted to Record Number@@new-case-page-record-number:Subject medical record number`,
  'patient-sex': $localize`:Placeholder indicating this field is related to patient gender@@new-case-page-patient-sex:Patient sex`,
  'subject-sex': $localize`:Placeholder indicating this field is related to patient gender@@new-case-page-subject-sex:Subject sex`,
  'required-region': $localize`:Required region RUO@@required-region:* Region`,
  'required-clinical-region': $localize`:Required clinical region IVD@@required-clinical-region:* Clinical region`,
  'required-case-id': $localize`:Required case id IVD@@required-case-id:* Case ID`,
  'required-research-case-id': $localize`:Required case id RUO@@required-research-case-id:* Research case ID`,
  'additional-attribute': $localize`:Long field for additional attribute RUO@@additional-attribute:Additional attribute (optional)`,
  'clinical-synopsis': $localize`:Long field for clinical synopsis IVD@@clinical-synopsis-optional:Clinical synopsis (optional)`,
  'institute-id': $localize`:Placeholder indicating this field is related to institution id@@new-case-page-institute-id:Institution ID`,
  'ordering-institute-id': $localize`:Placeholder indicating this field is related to ordering institution id@@new-case-page-ordering-institute-id:Ordering institution ID`,
  'institute-name': $localize`:Placeholder indicating this field is related to institution name@@new-case-page-institute-name:Institution name`,
  'ordering-institute-name': $localize`:Placeholder indicating this field is related to ordering institution name@@new-case-page-ordering-institute-name:Ordering institution name`,
  contact: $localize`:Placeholder indicating that this field is related to ordering contact@@new-case-page-contact:Contact`,
  'ordering-physician': $localize`:Placeholder indicating that this field is related to ordering physician@@new-case-page-ordering-physician:Ordering physician`
};

export function getI18nByKey(i18nKey: string): string | undefined {
  if (!i18nKey) {
    return;
  }
  return i18nTextMap[i18nKey];
}
